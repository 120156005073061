@import 'vars';
@import 'mixins';

/*
	Strata by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	.button {
		position: relative;
		-ms-behavior: url('css/ie/PIE.htc');
	}

/* Form */

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		position: relative;
		-ms-behavior: url('css/ie/PIE.htc');
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: $size-element-height;
		line-height: $size-element-height;
	}

	input[type="checkbox"],
	input[type="radio"] {
		& + label {
			&:before {
				display: none;
			}
		}
	}

/* Image */

	.image {
		position: relative;
		-ms-behavior: url('css/ie/PIE.htc');

		&:before, &:after {
			display: none !important;
		}

		img {
			position: relative;
			-ms-behavior: url('css/ie/PIE.htc');
		}
	}

/* Header */

	#header {
		background-image: url('../../images/bg.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		-ms-behavior: url('css/ie/backgroundsize.min.htc');
		
		h1 {
			color: $color-accent2-fg-bold;
		}
	}
	
/* Footer */

	#footer {
		.icons {
			a {
				color: $color-accent2-fg-bold;
			}
		}
	}
